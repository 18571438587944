import React from "react";

export default class ToggleFilter extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.focusSearch();
    }
  }

  render() {
    const { changeSearch, search } = this.props;

    return (
      <div style={{ padding: 8, display: "flex", alignItems: "center" }}>
        <span style={{ marginRight: 8 }}>Search:</span>
        <input
          type="text"
          onChange={changeSearch}
          value={search}
          style={{ minWidth: "200px" }}
          ref={(el) => (this.searchEl = el)}
        />
        <span style={{ flex: 1 }} />
      </div>
    );
  }

  focusSearch = () => {
    this.searchEl.focus();
  };
}
