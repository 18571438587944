import React from "react";
import { CpToggle, CpRadio, CpInput } from "canopy-styleguide!sofe";
import { Scoped, k } from "kremling";

export default class InspectorOptions extends React.Component {
  state = {
    featureToggleUrl: localStorage.getItem("feature-toggle-url") || "",
    customDomain: localStorage.getItem("sofe:local-domain") || "",
    commonDependencies: localStorage.getItem("common-deps") || "",
    noTracking: JSON.parse(
      localStorage.getItem("no-product-tracking") || "true"
    ),
    secretInspectorButton: JSON.parse(
      localStorage.getItem("secret-inspector-button") || "false"
    ),
  };
  updateState = (storage, state, value) => {
    this.setState({ [state]: value });
    localStorage.setItem(storage, value);
  };
  updateCommonDeps = (value) => {
    this.updateState("common-deps", "commonDependencies", value);
    document.cookie = `cp:common-deps=${value}; Path=/`;
  };
  render() {
    const {
      featureToggleUrl,
      customDomain,
      commonDependencies,
      noTracking,
      secretInspectorButton,
    } = this.state;

    return (
      <Scoped css={css}>
        <div className="cp-mt-24">
          <form>
            <div className="form-horizontal">
              <label>Sofe shorthand domain</label>
              <div>
                <CpInput
                  placeholder="https://localhost"
                  value={customDomain}
                  onChange={(value) =>
                    this.updateState("sofe:local-domain", "customDomain", value)
                  }
                  type="text"
                />
              </div>
            </div>
            <div className="form-horizontal">
              <label>Feature toggle domain</label>
              <div>
                <CpInput
                  placeholder="https://tm.canopytax.com"
                  value={featureToggleUrl}
                  onChange={(value) =>
                    this.updateState(
                      "feature-toggle-url",
                      "featureToggleUrl",
                      value
                    )
                  }
                  type="text"
                />
              </div>
            </div>
            <div className="form-horizontal">
              <label>Common dependencies</label>
              <div>
                <CpRadio
                  inline
                  value={commonDependencies}
                  onChange={this.updateCommonDeps}
                >
                  <CpRadio.Item id="prod">Prod</CpRadio.Item>
                  <CpRadio.Item id="dev">Developer</CpRadio.Item>
                  <CpRadio.Item id="upgrade">Upgrade</CpRadio.Item>
                </CpRadio>
              </div>
            </div>
            <div className="form-horizontal">
              <label htmlFor="no-tracking">Disable product tracking</label>
              <div>
                <CpToggle
                  id="no-tracking"
                  checked={!!noTracking}
                  onChange={(next) =>
                    this.updateState(
                      "no-product-tracking",
                      "noTracking",
                      !!next
                    )
                  }
                />
              </div>
            </div>
            <div className="form-horizontal">
              <label htmlFor="secret-button">Hide inspector button</label>
              <div>
                <CpToggle
                  id="secret-button"
                  checked={!!secretInspectorButton}
                  onChange={(next) =>
                    this.updateState(
                      "secret-inspector-button",
                      "secretInspectorButton",
                      !!next
                    )
                  }
                />
              </div>
            </div>
          </form>
        </div>
      </Scoped>
    );
  }
}

const css = k`
  .form-horizontal {
    display: flex;
    align-items: center;
    margin-bottom: .8rem;
    height: 3.6rem;
  }
  .form-horizontal > label {
    width: 20rem;
    display: block;
    text-align: right;
    margin-right: 1.6rem;
    color: #afafaf;
  }
  .form-horizontal > div {
    flex-grow: 1;
  }
  .form-horizontal input[type=text] {
    width: 30rem;
  }
`;
