import React from "react";

export default class ServiceFilter extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.visible && !prevProps.visible) {
      this.focusSearch();
    }
  }
  render() {
    const { squads, filteredSquad, setSquad, changeSearch, search } =
      this.props;

    const alphabetizedSquads = Object.keys(squads).sort();

    return (
      <div style={{ padding: 8, display: "flex", alignItems: "center" }}>
        <span style={{ width: 200 }}>Filter services by squad:</span>
        <select
          name="squads"
          value={filteredSquad}
          onChange={(e) => setSquad(e.target.value)}
          style={{ margin: "0 8px", width: "140px" }}
        >
          <option key="All" value="">
            All
          </option>
          {alphabetizedSquads.map((squad) => (
            <option value={squad} name={squad} key={squad}>
              {squad}
            </option>
          ))}
        </select>
        <span style={{ marginRight: 8 }}>Search:</span>
        <input
          type="text"
          onChange={changeSearch}
          value={search}
          style={{ minWidth: "200px" }}
          ref={(el) => (this.searchEl = el)}
        />
        <span style={{ flex: 1 }} />
      </div>
    );
  }

  focusSearch = () => {
    this.searchEl.focus();
  };
}
