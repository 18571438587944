import { omitBy } from "lodash";

export function getServiceOverrides() {
  return new Promise((resolve, reject) => {
    let services = {};

    for (let i = 0, iLength = localStorage.length; i < iLength; i++) {
      const localStorageKey = localStorage.key(i);
      if (localStorageKey.match(/sofe:(\S)+/g)) {
        const localStorageValue = localStorage.getItem(localStorageKey);
        services[/sofe:((\S)+)/g.exec(localStorageKey)[1]] = localStorageValue;

        setOverrideCookie(
          localStorageKey.slice("sofe:".length),
          localStorageValue
        );
      }
    }
    resolve(services);
  });
}

export function updateService(name, src) {
  return new Promise((resolve, reject) => {
    localStorage.setItem(`sofe:${name}`, src);
    setOverrideCookie(name, src);
    resolve();
  });
}

function setOverrideCookie(serviceName, overrideValue) {
  const oneYearFromNow = new Date(new Date().getTime() + 31536000000);
  document.cookie = `sofe:${serviceName}=${overrideValue}; Expires=${oneYearFromNow.toGMTString()}`;
}

export function removeService(name) {
  return new Promise((resolve, reject) => {
    localStorage.removeItem(`sofe:${name}`);
    document.cookie = `sofe:${name}=; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    resolve();
  });
}

export function getAvailableServices() {
  const manifest = omitBy(window.sofe_manifest || {}, (val, key) => {
    return key.includes("/!sofe") || !val.endsWith(".js");
  });
  return Promise.resolve(manifest);
}

export function clearAllSofeOverrides() {
  for (let key in localStorage) {
    if (
      key.startsWith("sofe:") ||
      key.startsWith("sofe-default-port:") ||
      key.startsWith("feature:")
    ) {
      localStorage.removeItem(key);
    }
  }
  const cookies = document.cookie.split("; ");
  cookies
    .filter((c) => c.startsWith("sofe:") || c.startsWith("cp:ft:"))
    .forEach((name) => {
      document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
    });
}

export function getAvailableSquads() {
  return [
    "blue",
    "corona",
    "gold",
    "green",
    "red",
    "infrastructure",
    "taxPrep",
  ];
}
