// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".backend-overrides-styles__exchangeOverride--3UxsA {\n  display: flex;\n  align-items: center;\n  height: 32px;\n}\n\n.backend-overrides-styles__exchangeOverride--3UxsA > * {\n  margin-right: 16px;\n}\n", ""]);
// Exports
exports.locals = {
	"exchangeOverride": "backend-overrides-styles__exchangeOverride--3UxsA"
};
module.exports = exports;
