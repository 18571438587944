import React from "react";
import { getOverrides, updateOverride } from "fetcher!sofe";

const defaultOverrideState = {
  url: "",
  delay: "",
  status: "",
  body: "",
};

function networkOverrideReducer(state, action) {
  switch (action.type) {
    case "url":
      return { ...state, url: action.url };
    case "delay":
      return {
        ...state,
        delay: action.delay ? action.delay * 1 : action.delay,
      };
    case "status":
      return {
        ...state,
        status: action.status ? action.status * 1 : action.status,
      };
    case "body":
      return { ...state, body: action.body };
    case "delayOnly":
      if (!state.delayOnly)
        return { ...state, delayOnly: !state.delayOnly, body: "", status: "" };
      else return { ...state, delayOnly: !state.delayOnly };
    case "reset":
      return defaultOverrideState;
  }
}

export function useNewNetworkOverride(initial = defaultOverrideState) {
  const [state, dispatch] = React.useReducer(networkOverrideReducer, initial);

  React.useEffect(() => {
    if (state.id) {
      updateOverride(state.id, {
        ...state,
        body:
          typeof state.body === "string" && state.body
            ? JSON.parse(state.body)
            : state.body,
      });
    }
  }, [state]);

  return [
    state,
    (e) => dispatch({ type: "url", url: e.target.value }),
    (e) => dispatch({ type: "delay", delay: e.target.value }),
    (e) => dispatch({ type: "body", body: e.target.value }),
    (e) => dispatch({ type: "status", status: e.target.value }),
    (e) => dispatch({ type: "reset" }),
    (e) => dispatch({ type: "delayOnly" }),
  ];
}

export function useNetworkOverrides() {
  const [overrides, setOverrides] = React.useState([]);
  const [refetch, setRefetch] = React.useState(1);

  React.useEffect(() => {
    setOverrides(getOverrides());

    return () => {};
  }, [refetch]);

  return [overrides, () => setRefetch(refetch + 1)];
}
