import React from "react";
import styles from "./backend-overrides.styles.css";
import { CpButton } from "canopy-styleguide!sofe";
import canopyUrls from "canopy-urls!sofe";

export default class BackendOverrides extends React.Component {
  state = {
    exchangeOverride: window.localStorage.getItem("exchange-override") || "",
  };
  inputRef = React.createRef();
  render() {
    const isInteg =
      canopyUrls.getEnvironment() === canopyUrls.INTEGRATION_ENVIRONMENT;
    return (
      <div>
        <h4>Set the X-CTX-Exchange-Override http header</h4>
        <div className={styles.exchangeOverride}>
          <label>exchange-override:</label>
          {isInteg ? (
            <input
              type="text"
              value={this.state.exchangeOverride}
              onChange={this.updateExchangeOverride}
              autoFocus
              ref={this.inputRef}
            />
          ) : (
            <div className="cps-warning">
              This feature is disabled for {canopyUrls.getEnvironment()}{" "}
              environments.
            </div>
          )}
          {this.state.exchangeOverride.trim().length > 0 && (
            <CpButton btnType="flat" onClick={this.removeExchangeOverride}>
              Remove
            </CpButton>
          )}
        </div>
        <div className="cps-margin-top-16">
          This will allow you to control which rabbitmq exchange requests get
          sent to, so that your local code can handle requests on integ instead
          of the integ code handling it.
        </div>
      </div>
    );
  }
  updateExchangeOverride = (evt) => {
    this.setState(
      {
        exchangeOverride: evt.target.value,
      },
      () => {
        window.localStorage.setItem(
          "exchange-override",
          this.state.exchangeOverride
        );
      }
    );
  };
  removeExchangeOverride = () => {
    this.setState({ exchangeOverride: "" }, () => {
      window.localStorage.removeItem("exchange-override");
      if (this.inputRef.current) {
        this.inputRef.current.focus();
      }
    });
  };
}
