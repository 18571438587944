import React from "react";
import { CpButton } from "canopy-styleguide!sofe";
import { useNewNetworkOverride } from "./network-overrides.helper.js";
import { removeOverride } from "fetcher!sofe";

export default function Override({ override, refetchOverrides }) {
  const [
    memoryOverride,
    updateUrl,
    updateDelay,
    updateBody,
    updateStatus,
    reset,
    toggleDelayOnly,
  ] = useNewNetworkOverride(override);

  return (
    <div
      style={{
        marginBottom: 8,
        paddingBottom: 4,
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #464545",
      }}
    >
      <span style={{ width: 70, textAlign: "center" }}>
        <label className="cps-toggle">
          <input
            type="checkbox"
            checked={memoryOverride.delayOnly}
            onChange={toggleDelayOnly}
          />
          <span />
        </label>
      </span>
      <input
        onChange={updateUrl}
        style={{ flex: 1, marginRight: 8 }}
        type="text"
        value={memoryOverride.url}
      />
      <input
        onChange={updateDelay}
        style={{ width: 100, marginRight: 8 }}
        type="text"
        value={memoryOverride.delay}
      />
      <input
        onChange={updateStatus}
        disabled={memoryOverride.delayOnly}
        style={{ width: 80, marginRight: 8 }}
        type="text"
        value={memoryOverride.status}
      />
      <input
        onChange={updateBody}
        disabled={memoryOverride.delayOnly}
        style={{ flex: 1, marginRight: 8 }}
        type="text"
        value={
          memoryOverride.body
            ? typeof memoryOverride.body === "string"
              ? memoryOverride.body
              : JSON.stringify(memoryOverride.body)
            : ""
        }
      />
      <CpButton
        icon="crud-trash-small"
        ariaText="Remove override"
        className="cps-white"
        onClick={() => {
          removeOverride(override.id);
          refetchOverrides();
        }}
      />
    </div>
  );
}
