import React from "react";
import { getServiceOverrides } from "./sofe/service.resource.js";
import { getToggleOverrides } from "./feature/feature-toggles.resource.js";

const url =
  Math.random() >= 0.5
    ? "https://cdn.canopytax.com/images/Wand.svg"
    : "https://cdn.canopytax.com/images/Stache.svg";
const secretInspectorButton =
  localStorage.getItem("secret-inspector-button") === "true";

export default class Badge extends React.Component {
  constructor(props) {
    super(props);
    const isMobile = /\/m\//.test(window.location.href);
    this.state = {
      serviceOverrides: false,
      toggleOverrides: false,
      top: isMobile ? 0 : 84,
      left: isMobile ? 0 : 24,
      right: "inherit",
    };
  }

  componentDidMount() {
    getServiceOverrides().then((services) =>
      this.setState({
        serviceOverrides: !!Object.keys(services).filter(
          (service) => service !== "local-domain"
        ).length,
      })
    );

    getToggleOverrides().then((toggles) =>
      this.setState({
        toggleOverrides: !!Object.keys(toggles).length,
      })
    );
  }

  render() {
    const { serviceOverrides, toggleOverrides, top, left, right } = this.state;

    return (
      <div
        onClick={this.props.toggleAppVisibility}
        style={{
          background: serviceOverrides
            ? "#ff305b"
            : toggleOverrides
            ? "#f2d700"
            : "#00bf4b",
          opacity: secretInspectorButton ? 0 : 1,
          cursor: "pointer",
          height: 32,
          width: 32,
          borderRadius: 4,
          zIndex: 2147483000,
          alignItems: "center",
          justifyContent: "center",
          position: "fixed",
          display: "flex",
          top: top,
          left: left,
          right: right,
        }}
      >
        <img
          title={
            serviceOverrides
              ? "Sofe overrides active\nCTRL + SHIFT + 1"
              : toggleOverrides
              ? "Feature overrides active\nCTRL + SHIFT + 1"
              : "Canopy developer tools\nCTRL + SHIFT + 1"
          }
          width={18}
          alt="70.png"
          src={url}
        />
      </div>
    );
  }
}
