import React from "react";
import { getAvailableSquads } from "./sofe/service.resource.js";
import styles from "./Button.style.css";

export default function SetSquadDialog(props) {
  const squads = getAvailableSquads();

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        height: "100%",
        zIndex: 1000000,
        display: "flex",
        justifyContent: "center",
        paddingTop: 100,
        textAlign: "center",
        backgroundColor: "rgba(34, 34, 34, .9)",
      }}
    >
      <div>
        <h2>
          A squad is required for many features in sofe-inspector. Which squad
          are you on?
        </h2>
        <div>
          {squads.map((squad) => (
            <label key={squad} className="cps-radio">
              <input
                type="radio"
                value={squad}
                name="squad"
                checked={props.squad === squad}
                onChange={(e) => {
                  props.setSquad(e.target.value);
                }}
              />
              <span style={{ paddingTop: 6 }}>{squad}</span>
            </label>
          ))}
        </div>
        <button
          style={{ marginTop: 24, width: 200 }}
          className={`${styles.btn}`}
          onClick={() => props.close()}
        >
          Close
        </button>
      </div>
    </div>
  );
}
