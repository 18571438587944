// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button-style__btn--MTup4 {\n  display: inline-block;\n  margin-bottom: 0px;\n  font-weight: normal;\n  text-align: center;\n  vertical-align: middle;\n  touch-action: manipulation;\n  cursor: pointer;\n  background-image: none;\n  border: 1px solid transparent;\n  white-space: nowrap;\n  padding: 4px 8px;\n  font-size: 15px;\n  line-height: 1.42857143;\n  border-radius: 4px;\n  user-select: none;\n  color: #ffffff;\n  background-color: #375a7f;\n  border-color: #375a7f;\n  border-width: 2px;\n}\n\n.Button-style__btnPrimary--1ueGd {\n  color: #ffffff;\n  background-color: #375a7f;\n  border-color: #375a7f;\n}\n\n.Button-style__btnDefault--1DjeK {\n  color: #ffffff;\n  background-color: #464545;\n  border-color: #464545;\n}\n\n.Button-style__btnDefault--1DjeK:hover {\n  color: #ffffff;\n  background-color: #2c2c2c;\n  border-color: #272727;\n}\n\n.Button-style__btn--MTup4:hover {\n  color: #ffffff;\n  text-decoration: none;\n}\n\n.Button-style__btnPrimary--1ueGd:hover {\n  color: #ffffff;\n  background-color: #28415b;\n  border-color: #253c54;\n}\n\n.Button-style__closeButton--1ulzT {\n  float: right;\n  cursor: pointer;\n  backgroundcolor: rgba(255, 255, 255, 0.05);\n  text-align: center;\n  vertical-align: middle;\n  padding: 4px 12px 4px 12px;\n  border-radius: 2;\n  line-height: 1.75;\n  font-weight: 600;\n  position: relative;\n  top: 12;\n}\n\n.Button-style__link--2gMqs {\n  color: #4a90e2;\n  text-decoration: none;\n}\n", ""]);
// Exports
exports.locals = {
	"btn": "Button-style__btn--MTup4",
	"btnPrimary": "Button-style__btnPrimary--1ueGd",
	"btnDefault": "Button-style__btnDefault--1DjeK",
	"closeButton": "Button-style__closeButton--1ulzT",
	"link": "Button-style__link--2gMqs"
};
module.exports = exports;
