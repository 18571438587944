import React from "react";
import { sortBy } from "lodash";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const extraEvents = [];

window.addEventListener("cp:inspector-timing", (event) => {
  if (!event.name) throw new Error("Timing errors must have a name property");

  extraEvents.push({
    name: event.name,
    time: new Date().getTime() - window.appLoaderInitialTime,
  });
});

export default class Performance extends React.Component {
  state = {
    includeCustom: true,
  };
  render() {
    const { fullScreen } = this.props;
    const { includeCustom } = this.state;
    const appLoaderPerf = window.appLoaderPerf || {};

    const data = [
      { name: "Tenant loaded", time: appLoaderPerf.tenant_loaded },
      { name: "User loaded", time: appLoaderPerf.logged_in_user_loaded },
      { name: "App Loader loaded", time: appLoaderPerf.app_loader_loaded },
      { name: "First sofe service", time: appLoaderPerf.first_sofe_service },
      { name: "Child apps declared", time: appLoaderPerf.child_apps_declared },
      { name: "Single-spa started", time: appLoaderPerf.single_spa_start },
      { name: "First app mounted", time: appLoaderPerf.first_app_mounted },
    ];

    const filteredData = sortBy(
      includeCustom ? [...extraEvents, ...data] : data,
      "time"
    );

    return (
      <div
        style={{
          marginTop: 16,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: fullScreen ? "column" : "row",
        }}
      >
        <BarChart
          width={window.innerWidth - 500}
          height={fullScreen ? Math.min(window.innerHeight - 350, 500) : 200}
          data={filteredData}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <XAxis dataKey="name" />
          <YAxis />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip labelStyle={{ color: "rgb(34, 34, 34)" }} />
          <Legend
            iconType="square"
            payload={[
              { dataKey: "time", value: "Time (ms)", color: "#8884d8" },
            ]}
          />
          <Bar dataKey="time" fill="#8884d8" />
        </BarChart>
        <div
          style={{
            alignSelf: fullScreen ? "center" : "start",
            marginTop: fullScreen ? 32 : 0,
          }}
        >
          <label className="cps-toggle">
            <input
              type="checkbox"
              value="option1"
              checked={includeCustom}
              onChange={() => this.setState({ includeCustom: !includeCustom })}
            />
            <span />
          </label>
          <span
            style={{
              position: "relative",
              top: "4px",
              left: "10px",
            }}
          >
            Show custom events
          </span>
          <span className="cps-help-block" style={{ marginTop: 8 }}>
            Add a custom timing event with:
            <pre>
              const event = new CustomEvent("cp:inspector-timing");
              <br />
              event.name = "Inspector loaded"; // the event must have a name
              <br />
              window.dispatchEvent(event);
            </pre>
          </span>
        </div>
      </div>
    );
  }
}
