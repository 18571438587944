import React from "react";
import { addOverride } from "fetcher!sofe";
import { CpButton } from "canopy-styleguide!sofe";
import Override from "./override.component.js";
import {
  useNetworkOverrides,
  useNewNetworkOverride,
} from "./network-overrides.helper.js";

export default function NetworkOverrides(props) {
  const [overrides, refetchOverrides] = useNetworkOverrides();
  const mainInput = React.useRef(null);
  const [
    memoryOverride,
    updateUrl,
    updateDelay,
    updateBody,
    updateStatus,
    reset,
    toggleDelayOnly,
  ] = useNewNetworkOverride();

  React.useEffect(() => {
    mainInput.current.focus();
  }, []);

  return (
    <div>
      <div
        style={{
          overflow: "auto",
          height: props.fullScreen ? "calc(100% - 200px)" : 320,
          border: "1px solid #464545",
          padding: 8,
        }}
      >
        <div
          style={{
            display: "flex",
            fontWeight: "bold",
            borderBottom: "1px solid #464545",
            textAlign: "center",
            paddingBottom: 8,
            marginBottom: 8,
          }}
        >
          <span style={{ width: 70 }}>Delay Only</span>
          <span style={{ flex: 1 }}>URL to match (includes)</span>
          <span style={{ width: 100 }}>Delay (ms)</span>
          <span style={{ width: 80 }}>Status</span>
          <span style={{ flex: 1, marginRight: 40 }}>Response body</span>
        </div>
        {overrides.map((override, index) => (
          <Override
            override={override}
            key={index}
            refetchOverrides={refetchOverrides}
          />
        ))}
        <div
          key={"add"}
          style={{
            marginBottom: 8,
            paddingBottom: 4,
            display: "flex",
            alignItems: "center",
            borderBottom: "1px solid #464545",
          }}
        >
          <span style={{ width: 70, textAlign: "center" }}>
            <label className="cps-toggle">
              <input
                checked={memoryOverride.delayOnly}
                onChange={toggleDelayOnly}
                type="checkbox"
              />
              <span />
            </label>
          </span>
          <input
            ref={mainInput}
            style={{ flex: 1, marginRight: 8 }}
            type="text"
            value={memoryOverride.url}
            onChange={updateUrl}
          />
          <input
            style={{ width: 100, marginRight: 8 }}
            type="text"
            value={memoryOverride.delay}
            onChange={updateDelay}
          />
          <input
            style={{ width: 80, marginRight: 8 }}
            type="text"
            value={memoryOverride.status}
            onChange={updateStatus}
          />
          <input
            style={{ flex: 1, marginRight: 8 }}
            type="text"
            value={memoryOverride.body}
            onChange={updateBody}
          />
          <CpButton
            icon="add-circle-filled"
            ariaText="Add override"
            tooltip="Add override"
            className={memoryOverride.url ? "cps-white" : "cps-gray"}
            onClick={() => {
              if (memoryOverride.url) {
                addOverride(memoryOverride);
                reset();
                refetchOverrides();
                mainInput.current.focus();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}
