import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./app.component.js";
import Badge from "./badge.component.js";
import { queryClient } from "fetcher!sofe";

const ReactQueryDevtoolsProduction = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/modern/production.js").then(d => ({
    default: d.ReactQueryDevtools,
  }))
);

interface RootState {
  appDisplayed: boolean;
  showQueryDevtools: boolean;
}

interface RootProps {
  [key: string]: any;
}

class Root extends React.Component<RootProps, RootState> {
  constructor(props: RootProps) {
    super(props);
    this.state = {
      appDisplayed: false,
      showQueryDevtools: localStorage.getItem("cp:sofe-inspector:show-query-devtools") === "true",
    };
  }

  render() {
    // Doesn't hurt to show the badge even when the app is open, since it's z-index is smaller than App's
    const { appDisplayed, showQueryDevtools } = this.state;

    return (
      <div>
        <App
          toggleAppVisibility={this.toggleAppVisibility}
          hideApp={this.hideApp}
          visible={appDisplayed}
          queryDevtoolsVisible={showQueryDevtools}
          onToggleQueryDevtools={this.toggleQueryDevtools}
          {...this.props}
        />
        <Badge toggleAppVisibility={this.toggleAppVisibility} {...this.props} />
        {showQueryDevtools && (
          <Suspense fallback={null}>
            <div style={{ position: "fixed", zIndex: 100002 }}>
              <ReactQueryDevtoolsProduction client={queryClient} />
            </div>
          </Suspense>
        )}
      </div>
    );
  }

  hideApp = () => {
    this.setState({
      appDisplayed: false,
    });
  };

  toggleAppVisibility = () => {
    this.setState((prevState) => ({ appDisplayed: !prevState.appDisplayed }));
  };

  toggleQueryDevtools = () => {
    this.setState((prevState) => {
      const newShowQueryDevtools = !prevState.showQueryDevtools;
      localStorage.setItem("cp:sofe-inspector:show-query-devtools", newShowQueryDevtools.toString());
      return { showQueryDevtools: newShowQueryDevtools };
    });
  };
}

export function renderRoot(props: RootProps) {
  const wrapper = document.createElement("div");
  wrapper.id = "sofe-inspector";
  document.body.appendChild(wrapper);

  const root = createRoot(wrapper);
  root.render(<Root {...props} />);
}
