import React from "react";
import { chain, has } from "lodash";
import { CpLoader } from "canopy-styleguide!sofe";
import {
  getToggles,
  getToggleOverrides,
  setToggle,
  removeToggle,
} from "./feature-toggles.resource.js";
import ToggleFilter from "./toggle-filter.component.js";

export default class FeatureToggles extends React.Component {
  state = {
    toggles: {},
    loading: true,
    error: false,
    search: "",
  };
  componentDidMount() {
    this.refreshToggles();
  }
  loadOverrides = () => {
    return getToggleOverrides().then((overrides) =>
      this.setState({ overrides })
    );
  };
  render() {
    const { toggles, loading, error, overrides, search } = this.state;

    if (error) return <h3>Oops, something went wrong with feature toggles!</h3>;

    return (
      <div>
        {loading ? (
          <CpLoader dark />
        ) : (
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: 8,
                height: "30px",
              }}
            >
              <ToggleFilter
                changeSearch={(e) => this.setState({ search: e.target.value })}
                search={search}
                ref={(el) => (this.serviceFilterComponent = el)}
              />
            </div>
            <div style={{ display: "flex", padding: 8, fontWeight: "bold" }}>
              <span style={{ width: 300 }}>Feature</span>
              <span style={{ width: 138 }}>Status</span>
              <span style={{ width: 160 }}>Override</span>
              <span style={{ flex: 1 }} />
            </div>
            <div
              style={{
                overflow: "auto",
                height: 300,
                border: "1px solid #464545",
                padding: 8,
              }}
            >
              {chain(Object.keys(toggles).sort())
                .filter((service) => service.includes(search))
                .sortBy((toggle) =>
                  has(overrides, toggle) ? `a${toggle}` : `b${toggle}`
                )
                .map((toggle) => {
                  const isOn = toggles[toggle];
                  const hasOverride = has(overrides, toggle);

                  return (
                    <div
                      style={{
                        display: "flex",
                        paddingBottom: 4,
                        marginBottom: 4,
                        alignItems: "center",
                        borderBottom: hasOverride
                          ? "1px solid white"
                          : "1px solid #464545",
                      }}
                      key={toggle}
                    >
                      <span style={{ width: 300 }}>{toggle}</span>
                      <span
                        style={{
                          fontWeight: "bold",
                          color: hasOverride
                            ? "#464545"
                            : isOn
                            ? "green"
                            : "red",
                          width: 80,
                        }}
                      >
                        {isOn ? "ON" : "OFF"}
                      </span>
                      <label
                        className="cps-radio"
                        style={{ position: "relative", top: 2 }}
                      >
                        <input
                          type="radio"
                          value="1"
                          name={toggle}
                          checked={overrides[toggle] === "off"}
                          onChange={this.setOverride.bind(this, toggle, "off")}
                        />
                        <span style={{ paddingTop: 6 }}>Off</span>
                      </label>
                      <label
                        className="cps-radio"
                        style={{ position: "relative", top: 2 }}
                      >
                        <input
                          type="radio"
                          value="1"
                          name={toggle}
                          checked={!hasOverride}
                          onChange={this.removeOverride.bind(this, toggle)}
                        />
                        <span style={{ paddingTop: 6 }}>Default</span>
                      </label>
                      <label
                        className="cps-radio"
                        style={{ position: "relative", top: 2 }}
                      >
                        <input
                          type="radio"
                          value="1"
                          name={toggle}
                          checked={overrides[toggle] === "on"}
                          onChange={this.setOverride.bind(this, toggle, "on")}
                        />
                        <span style={{ paddingTop: 6 }}>On</span>
                      </label>
                    </div>
                  );
                })
                .value()}
            </div>
          </div>
        )}
      </div>
    );
  }

  removeOverride = (name) => {
    removeToggle(name)
      .then(this.loadOverrides)
      .then(() => {
        document.cookie = `cp:ft:${name}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; Path=/`;
      })
      .then(() => {
        // This helps avoid weird scroll jumping and is nice to be able to immediately start searching after setting an override
        this.serviceFilterComponent.focusSearch();
      })
      .catch(this.setError);
  };

  setOverride = (name, value, focusSearchAfterward = true) => {
    setToggle(name, value)
      .then(this.loadOverrides)
      .then(() => {
        document.cookie = `cp:ft:${name}=${value}; Path=/ `;
      })
      .then(() => {
        if (focusSearchAfterward) {
          // This helps avoid weird scroll jumping and is nice to be able to immediately start searching after setting an override
          this.serviceFilterComponent.focusSearch();
        }
      })
      .catch(this.setError);
  };

  setError = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  refreshToggles = () => {
    this.loadOverrides()
      .then(getToggles)
      .then((toggles) => this.setState({ toggles, loading: false }))
      .catch(this.setError);
  };
}
